.recuperacaoEstrutural {
    background-color: #ececec;
    text-align: justify;
}

.recuperacaoEstrutural h1 {
    font-size: 26px;
}

.recuperacaoEstrutural h4 {
    font-size: Larger;
}

.recuperacaoEstrutural img {
    width: 500px;
    max-height: 500px;
}

.figure-caption {
    color: #000 !important;
}
