.sondagemGeotecnica {
    background-color: #ececec;
    text-align: justify;
}

.sondagemGeotecnica h1 {
    font-size: 26px;
}

.sondagemGeotecnica hr{
    margin-bottom: 30px;
}

.sondagemGeotecnica span img {
    float: right;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    margin: 0 0 10px 20px !important;
}
