.sobre {
  background-color: #ececec;
}

.sobre h1 {
  font-size: 26px;
}

.sobre p {
  font-size: 14px;
}

.sobre img {
  width: 160px;
}

.sobre h5 {
  margin: 20px 0;
}

.step {
  float: left;
  width: 25%;
  padding: 15px;
  color: #fff;
  min-height: 230px;
  position: relative;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #34BE82;
  margin: 20px 0;
}

.step1 {
  background-color: #5a5a5a;
}

.step2 {
  background-color: #4a4a4a;
}

.step3 {
  background-color: #3a3a3a;
}

.diretores {
  background: #797979;
  color: #fff;
  padding: 15px;
  min-height: auto;
  position: relative;
  text-shadow: 1px 1px 0 rgb(0 0 0 / 40%);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin: 15px;
}

.diretores::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  margin-top: -5px;
  border: 5px solid transparent;
  border-left-color: #797979;
}

.diretores h4 {
  margin-top: 5px;
  font-family: Istok Web;
  font-size: 15px;
  line-height: 20px;
}

.sobre-imagens img {
  transition: .1s ease;
  margin: 20px 0;
}

.sobre-imagens img:hover {
  cursor: pointer;
  border: 5px solid #3d3d3d;
}

.sobre a {
  color: #34BE82;
  text-decoration: none;
}

.sobre a:hover {
  color: #12725f;
  text-decoration: underline;
}
