.logo {
    max-width: 150px;
}

.header-menu {
    background-color: #fff !important;
    transition: 0.5s ease;
    padding: 10px 0 !important;
    z-index: 1000;
}

.nav-links {
    margin: 0 1em;
    text-decoration: none;
    color: #3d3d3d;
    list-style-type: none;
    font-weight: bold;
    transition: 0.5s ease;
}

.nav-links a:hover {
    color: #2b5674 !important;
}

.dropdown-menu {
    background-color: #2b5674 !important;
    padding: 0 !important;
}

.dropdown-menu a {
    color: #fff;
    height: 100%;
    font-weight: bold;
    line-height: 27px;
    font-size: 12px;
    text-decoration: none;
    margin: 0 !important;
    width: 100%;
    transition: all 0.1s ease !important;
}

.dropdown-menu a:hover {
    color: #3d3d3d !important;
    background-color: #fff;
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    transition: all 1.5s ease !important;
    margin: 0 !important;
}

.navbar-nav {
    width: 100% !important;
    justify-content: space-between !important;
}

@media screen and (max-width: 375px) {
    .header-menu {
        align-items: center !important;
        text-align: center !important;
    }
    .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
        display: block !important;
    }
    .logo {
        width: 90%
    }
}
