.sondagemCampanula {
    background-color: #ececec;
    text-align: justify;
}

.sondagemCampanula h1 {
    font-size: 26px;
}

.sondagemCampanula h4 {
    font-size: Larger;
    margin-top: 40px;
}

.sondagemCampanula .img-extra {
    width: 100%;
    margin: 0 20px 10px 0;
}

.sondagemCampanula p img {
    float: right;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    margin: 0 0 10px 20px !important;
}

@media screen and (max-width: 1200px) {
    .sondagemCampanula .row {
        display: block;
    }
}
