.section-2-home {
  padding: 5rem;
  text-align: center;
  font-size: 30px;
}

.section-2-home a {
  text-decoration: none !important;
  font-size: 1rem;
  color: #fff !important;
  background-color: #2b5674;
  padding: 10px;
  border-radius: 1rem;
}

@media screen and (max-width: 768px) {
  .section-2-home a {
    font-size: 1rem;
  }
}
