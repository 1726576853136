.sondagemFlutuante {
    background-color: #ececec;
    text-align: justify;
}

.sondagemFlutuante h1 {
    font-size: 26px;
}

.sondagemFlutuante h4 {
    font-size: Larger;
    margin-top: 40px;
}

.sondagemFlutuante .img-extra {
    width: 100%;
    margin: 0 20px 10px 0;
}

.sondagemFlutuante li {
    margin-left: 30px;
}
