.clientes h1 {
  font-size: 26px;
}

.clientes p {
  font-size: 14px;
}

.clientes img {
  background-color: #fff;
  transition: .1s ease;
  margin: 10px 0;
  width: 100%;
  min-width: 160px;
  max-width: 160px;
}

.clientes img:hover {
  border: 3px solid #3d3d3d;
  cursor: pointer;
}
