.accordion-button:not(.collapsed) {
    background-color: #fff !important;
    color: #000 !important;
    font-weight: bold;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
    border: none !important;
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%) I !important;
}
