.contencaoDeEncostas {
    background-color: #ececec;
    text-align: justify;
}

.contencaoDeEncostas h1 {
    font-size: 26px;
}

.contencaoDeEncostas .img-extra {
    width: 100%;
    margin: 50px 20px 10px 0;
}

.contencaoDeEncostas p img {
    float: right;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    margin: 0 0 10px 20px !important;
}
