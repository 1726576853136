.texto-para-canvas {
    color: #1164e2;
    font-weight: bold;
    cursor: pointer;
}

.texto-para-canvas:hover {
    color: #000000;
    font-weight: bold;
    cursor: pointer;
}
