.sondagemPlataforma {
    background-color: #ececec;
    text-align: justify;
}

.sondagemPlataforma h1 {
    font-size: 26px;
}

.sondagemPlataforma h4 {
    font-size: Larger;
    margin-top: 40px;
}

.sondagemPlataforma .img-extra {
    width: 100%;
    margin: 50px 20px 10px 0;
}

.img-extra1 {
    width: 100%;
    margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
    .sondagemPlataforma .row {
        display: block;
    }
}
