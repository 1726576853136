body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    text-decoration: none;
    text-align: justify;
    overflow-x: hidden;
    box-sizing: border-box;
}

main {
    padding: 150px 0 20px 0 !important;
    background-color: #ececec;
}

main h1 {
    font-size: 26px;
}

main p {
    font-size: 14px;
}

