.footer {
    padding: 40px 0;
    text-align: center;
    background: linear-gradient(to bottom,#2b5674 35%, #c5d3fa);
    color: #fff;
    text-shadow: 0.1em 0.1em 0.2em black;
}

.footer-infos h5, .footer-infos p{
    text-align: left !important;
}

.footer-infos h5 {
    margin-bottom: 15px;
    font-size: 14px;
}

.footer-infos p {
    line-height: 1em;
    font-size: 14px;
}

.footer-infos a {
    text-decoration: underline;
    color: #fff;
    transition: .1s ease;
}

.footer-infos a:hover {
    color: #d5d5d5;
}

.footer hr {
    border-top: 5px solid #ffffff;
    margin: 20px 0;
    opacity: 1;
}

.copyrigth p {
    font-size: 12px;
    text-shadow: 0.3em 0.3em 0.3em black;
}

@media screen and (max-width: 768px) {
    .footer-infos {
        display: block !important;
    }
}
