.obras h1 {
  font-size: 26px;
}

.obras h3 {
  font-size: larger;
  margin-top: 10px;
}

.obras li {
  font-size: 14px;
  line-height: 30px;
  list-style: none;
}

.obras li {
  padding-left: 1em;
  text-indent: -1em;
}

.obras li:before {
  content: "-";
  padding-right: 5px;
}

.obras h4 {
  font-size: 15px !important;
  margin-top: 10px;
  font-weight: bold !important;
}