.contatos {
  font-size: 14px !important;
}

.contatos h1 {
  font-size: 26px !important;
}

.contatos h3 {
  font-size: larger;
}

.contatos a {
  color: #303ec5;
}

.contatos a:hover {
  color: #1e2d85;
}
