.plataformasElevatorias {
    background-color: #ececec;
}

.plataformasElevatorias h1 {
    font-size: 26px;
}

.plataformasElevatorias p {
    font-size: 14px;
    margin-top: 20px;
}

.plataformasElevatorias img {
        max-width: 100%;
        vertical-align: middle;
}

.plataformasElevatorias .img-extra {
    width: 100%;
    margin: 0 20px 10px 0;
}

.img-extra2 {
    min-height: 424px;
}

@media screen and (max-width: 1200px) {
    .plataformasElevatorias .row {
        display: block;
    }
    .img-extra2 {
        min-height: 624px !important;
    }
}

@media screen and (max-width: 1400px) {
    .img-extra2 {
        min-height: 364px !important;
    }
}

@media screen and (max-width: 992px) {
    .img-extra2 {
        min-height: 464px !important;
    }
}

@media screen and (max-width: 768px) {
    .img-extra2 {
        min-height: 344px !important;
    }
}

@media screen and (max-width: 540px) {
    .img-extra2 {
        min-height: auto !important;
    }
}



