.fundacoesEspeciais {
    background-color: #ececec;
    text-align: justify;
}

.fundacoesEspeciais h1 {
    font-size: 26px;
}

.fundacoesEspeciais .img-extra {
    width: 100%;
    margin: 50px 20px 10px 0;
}

.fundacoesEspeciais p img {
    float: right;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
    margin: 0 0 10px 20px !important;
}

@media screen and (max-width: 1200px) {
    .fundacoesEspeciais .row {
        display: block;
    }
    .fundacoesEspeciais p img {
        float: none;
        display: block;
    }
}
